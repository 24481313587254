@font-face {
  font-family: 'MisoRegular';
  /* src: url('https://royplus.de/css/fonts/miso-regular-webfont.eot'); */
  src: url('fonts/miso-regular-webfont.eot');
  src: url('fonts/miso-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/miso-regular-webfont.woff') format('woff'),
  url('fonts/miso-regular-webfont.ttf') format('truetype'),
  url('fonts/miso-regular-webfont.svg#MisoRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BebasRegular';
  src: url('fonts/BebasNeue-webfont.eot');
  src: url('fonts/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/BebasNeue-webfont.woff') format('woff'),
  url('fonts/BebasNeue-webfont.ttf') format('truetype'),
  url('fonts/BebasNeue-webfont.svg#BebasRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  /* background-color: #282c34; */
  /* background-color: rgb(35, 31, 30); */
  background-color: rgb(38, 34, 33);
  /* background-color: rgb(98, 92, 92); */
  color: white;
  font-size: calc(12px + 1vmin);
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif; */
  font-family: "Helvetica Neue", Helvetica, Arial,
    sans-serif;
 
  margin: 0 auto;
  padding: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "MisoRegular", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

a {
  color: rgb(50, 137, 120);
}

a:hover {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

article {
  padding: 1vw;
  /* border: 1px dotted; */
  /* background-color: rgba(200, 200, 200, 80%); */
  /* margin: 0.5% 1%; */
}

i {
  padding: 0 5px;
}

img.full-width {
  width: 100%;
}

img.half-width {
  width: 50%;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.center {
  text-align: center;
}

p.quote {
  font-family: "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: italic;
  margin: 10px 10px;
  padding: 10px 10px;
}
