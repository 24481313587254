.Player {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  border: 2px solid;
  border-radius: 5px;
  padding: 5px 5px;
}

.Player i {
  padding: 0 5px;
}

.Player i:hover {
  color: rgba(255, 255, 255, 0.9);
}

/* do not collide with nav bar */
@media only screen and (max-width: 950px) {
  .Player {
    padding: 2px 0 2px 0;
    margin-left: -10px;
    margin-right: 10px;
  }

  .Player .details {
    display: none;
  }
}

/* iphone 4 format */
@media only screen and (max-width: 360px) {
  .Player {
    padding: 2px 0 2px 0;
    margin-left: -10px;
    margin-right: 10px;
  }
}
