li.Event>div {
  border-radius: 5px;
  padding: 0 5px;
  margin: 5px 0;
}

li.Event>div.inactive {
  background-color: rgba(150, 150, 150, 0.2);
}

li.Event>div.active {
  background-color: rgba(0, 87, 70, 0.25);
}

li.Event .date {
  background-color: rgba(150, 150, 150, 0.2);
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 10px;
}

li.Event p:last-child {
  margin-bottom: 0;
}

li.Event>div.strikethrough {
  position: relative;
}

li.Event>div.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 35px;
  right: 0;
  border-top: 3px solid;
  border-color: rgba(250, 0, 0, 1);

  -webkit-transform:rotate(-10deg);
  -moz-transform:rotate(-10deg);
  -ms-transform:rotate(-10deg);
  -o-transform:rotate(-10deg);
  transform:rotate(-10deg);
}

li.Event>div.strikethrough:after {
  position: absolute;
  content: "Verpasst!";
  /* text-decoration: underline; */
  font-size: 130%;
  color: rgba(250, 0, 0, 1);
  left: 120px;
  top: -10px;
  right: 0;

  -webkit-transform:rotate(-10deg);
  -moz-transform:rotate(-10deg);
  -ms-transform:rotate(-10deg);
  -o-transform:rotate(-10deg);
  transform:rotate(-10deg);
}

@media only screen and (max-width: 1200px) {
  li.Event>div.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 30px;
    right: 0;
    border-top: 3px solid;
    border-color: rgba(250, 0, 0, 1);

    -webkit-transform:rotate(-3deg);
    -moz-transform:rotate(-3deg);
    -ms-transform:rotate(-3deg);
    -o-transform:rotate(-3deg);
    transform:rotate(-3deg);
  }

  li.Event>div.strikethrough:after {
    position: absolute;
    content: "Verpasst!";
    /* text-decoration: underline; */
    font-size: 130%;
    color: rgba(250, 0, 0, 1);
    left: 150px;
    top: -20px;
    right: 0;

    -webkit-transform:rotate(-3deg);
    -moz-transform:rotate(-3deg);
    -ms-transform:rotate(-3deg);
    -o-transform:rotate(-3deg);
    transform:rotate(-3deg);
  }
}

@media only screen and (max-width: 768px) {
  li.Event>div.strikethrough:after {
    position: absolute;
    content: "Verpasst!";
    /* text-decoration: underline; */
    font-size: 130%;
    color: rgba(250, 0, 0, 1);
    left: 150px;
    top: -5px;
    right: 0;

    -webkit-transform:rotate(-3deg);
    -moz-transform:rotate(-3deg);
    -ms-transform:rotate(-3deg);
    -o-transform:rotate(-3deg);
    transform:rotate(-3deg);
  }
}
