.DownloadList .center {
  text-align: center;
}

.DownloadList .box {
  background-color: rgba(0, 87, 70, 0.25);
  border-radius: 5px;
  width: 100%;
  min-height: 5vmin;
  padding: 1vmin;
  text-align: center;
  vertical-align: middle;
}

.DownloadList img.logo {
  padding: 1vmin;
  width: 33%;
}
