@font-face {
  font-family: 'MisoRegular';
  /* src: url('https://royplus.de/css/fonts/miso-regular-webfont.eot'); */
  src: url(/static/media/miso-regular-webfont.fefd71fa.eot);
  src: url(/static/media/miso-regular-webfont.fefd71fa.eot?#iefix) format('embedded-opentype'),
  url(/static/media/miso-regular-webfont.49e87f0b.woff) format('woff'),
  url(/static/media/miso-regular-webfont.cd8d580e.ttf) format('truetype'),
  url(/static/media/miso-regular-webfont.5e6cb765.svg#MisoRegular) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BebasRegular';
  src: url(/static/media/BebasNeue-webfont.069d2143.eot);
  src: url(/static/media/BebasNeue-webfont.069d2143.eot?#iefix) format('embedded-opentype'),
  url(/static/media/BebasNeue-webfont.7f46d65f.woff) format('woff'),
  url(/static/media/BebasNeue-webfont.ace28fcc.ttf) format('truetype'),
  url(/static/media/BebasNeue-webfont.44e8483a.svg#BebasRegular) format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  /* background-color: #282c34; */
  /* background-color: rgb(35, 31, 30); */
  background-color: rgb(38, 34, 33);
  /* background-color: rgb(98, 92, 92); */
  color: white;
  font-size: calc(12px + 1vmin);
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif; */
  font-family: "Helvetica Neue", Helvetica, Arial,
    sans-serif;
 
  margin: 0 auto;
  padding: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "MisoRegular", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

a {
  color: rgb(50, 137, 120);
}

a:hover {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

article {
  padding: 1vw;
  /* border: 1px dotted; */
  /* background-color: rgba(200, 200, 200, 80%); */
  /* margin: 0.5% 1%; */
}

i {
  padding: 0 5px;
}

img.full-width {
  width: 100%;
}

img.half-width {
  width: 50%;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.center {
  text-align: center;
}

p.quote {
  font-family: "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: italic;
  margin: 10px 10px;
  padding: 10px 10px;
}

.Player {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  border: 2px solid;
  border-radius: 5px;
  padding: 5px 5px;
}

.Player i {
  padding: 0 5px;
}

.Player i:hover {
  color: rgba(255, 255, 255, 0.9);
}

/* do not collide with nav bar */
@media only screen and (max-width: 950px) {
  .Player {
    padding: 2px 0 2px 0;
    margin-left: -10px;
    margin-right: 10px;
  }

  .Player .details {
    display: none;
  }
}

/* iphone 4 format */
@media only screen and (max-width: 360px) {
  .Player {
    padding: 2px 0 2px 0;
    margin-left: -10px;
    margin-right: 10px;
  }
}

.Header {
  background-color: rgb(0, 87, 70);
  width: 100%;
  margin-top: 1%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "BebasRegular", "Helvetica Neue", Helvetica, Arial,
    sans-serif; */
  font-family: "BebasRegular", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.navbar-nav {
  /* margin-right: 20vw; */
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 2vw;
  border: 2px solid;
  border-radius: 5px;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  /* color: rgb(198, 192, 192); */
  color: rgba(255, 255, 255, 0.9);
}

@media only screen and (max-width: 950px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 20px;
    padding: 2px;
    margin: 2px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-light .navbar-nav .nav-link {
    border: 0;
    padding: 1px 5px;
    margin: 1px 0;
  }
}

.navbar-toggler {
  border-width: 2px;
}

.navbar-toggler-icon {
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5);") */
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1);")
}

/* iphone 4 format */
@media only screen and (max-width: 320px) {
  .navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.5);
    padding: 0;
  }
}

.standalone-content {
  margin: 10vh 10vw;
  padding: 10vh 10vw;
}

ul.Tracklist {
  list-style: none;
  /* margin-block-start: 1em; */
  /* margin-block-end: 1em; */
  /* margin-inline-start: 0px; */
  /* margin-inline-end: 0px; */
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.DownloadList .center {
  text-align: center;
}

.DownloadList .box {
  background-color: rgba(0, 87, 70, 0.25);
  border-radius: 5px;
  width: 100%;
  min-height: 5vmin;
  padding: 1vmin;
  text-align: center;
  vertical-align: middle;
}

.DownloadList img.logo {
  padding: 1vmin;
  width: 33%;
}

li.Event>div {
  border-radius: 5px;
  padding: 0 5px;
  margin: 5px 0;
}

li.Event>div.inactive {
  background-color: rgba(150, 150, 150, 0.2);
}

li.Event>div.active {
  background-color: rgba(0, 87, 70, 0.25);
}

li.Event .date {
  background-color: rgba(150, 150, 150, 0.2);
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 10px;
}

li.Event p:last-child {
  margin-bottom: 0;
}

li.Event>div.strikethrough {
  position: relative;
}

li.Event>div.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 35px;
  right: 0;
  border-top: 3px solid;
  border-color: rgba(250, 0, 0, 1);

  -webkit-transform:rotate(-10deg);
  transform:rotate(-10deg);
}

li.Event>div.strikethrough:after {
  position: absolute;
  content: "Verpasst!";
  /* text-decoration: underline; */
  font-size: 130%;
  color: rgba(250, 0, 0, 1);
  left: 120px;
  top: -10px;
  right: 0;

  -webkit-transform:rotate(-10deg);
  transform:rotate(-10deg);
}

@media only screen and (max-width: 1200px) {
  li.Event>div.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 30px;
    right: 0;
    border-top: 3px solid;
    border-color: rgba(250, 0, 0, 1);

    -webkit-transform:rotate(-3deg);
    transform:rotate(-3deg);
  }

  li.Event>div.strikethrough:after {
    position: absolute;
    content: "Verpasst!";
    /* text-decoration: underline; */
    font-size: 130%;
    color: rgba(250, 0, 0, 1);
    left: 150px;
    top: -20px;
    right: 0;

    -webkit-transform:rotate(-3deg);
    transform:rotate(-3deg);
  }
}

@media only screen and (max-width: 768px) {
  li.Event>div.strikethrough:after {
    position: absolute;
    content: "Verpasst!";
    /* text-decoration: underline; */
    font-size: 130%;
    color: rgba(250, 0, 0, 1);
    left: 150px;
    top: -5px;
    right: 0;

    -webkit-transform:rotate(-3deg);
    transform:rotate(-3deg);
  }
}

ul.EventList {
  list-style: none;
  /* margin-block-start: 1em; */
  /* margin-block-end: 1em; */
  /* margin-inline-start: 0px; */
  /* margin-inline-end: 0px; */
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

div.InstagramElement {
  text-align: center;
  font-size: calc(10px + 1vmin);
  padding-bottom: 1vh;
}

.InstagramElement img.instagram {
  width: 100%;
}

.Home .cover {
  /* width: 100%; */
  margin-bottom: 1vw;
}

@media only screen and (max-width: 768px) {
  .Home .cover {
    /* width: 96%; */
  }
}

.Imprint h2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.Main {
  padding-top: 1vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  -webkit-justify-content: center;
          justify-content: center;
}

.Footer {
  text-align: center;
  font-size: calc(10px + 1vmin);
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* colorful spans */

.color:nth-child(4n) { 
  color:hsl(50, 75%, 55%); 
  text-shadow:1px 1px hsl(50, 75%, 45%), 2px 2px hsl(50, 45%, 45%), 3px 3px hsl(50, 45%, 45%), 4px 4px hsl(50, 75%, 45%); 
}
.color:nth-child(4n-1) { 
  color:hsl(135, 35%, 55%); 
  text-shadow:1px 1px hsl(135, 35%, 45%), 2px 2px hsl(135, 35%, 45%), 3px 3px hsl(135, 35%, 45%), 4px 4px hsl(135, 35%, 45%); 
}
.color:nth-child(4n-2) { 
  color:hsl(155, 35%, 60%); 
  text-shadow:1px 1px hsl(155, 25%, 50%), 2px 2px hsl(155, 25%, 50%), 3px 3px hsl(155, 25%, 50%), 4px 4px hsl(140, 25%, 50%); 
}
.color:nth-child(4n-3) { 
  color:hsl(30, 65%, 60%); 
  text-shadow:1px 1px hsl(30, 45%, 50%), 2px 2px hsl(30, 45%, 50%), 3px 3px hsl(30, 45%, 50%), 4px 4px hsl(30, 45%, 50%); 
}

.color-fixed { 
  color:hsl(155, 35%, 60%); 
  text-shadow:1px 1px hsl(11, 11%, 11%), 2px 2px hsl(155, 25%, 50%), 3px 3px hsl(155, 25%, 50%), 4px 4px hsl(140, 25%, 50%); 
}

/* animations */

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:hover.spin-hover { -webkit-animation: spin infinite 20s linear; animation: spin infinite 20s linear; }


@-webkit-keyframes float {
  0%,100%{ -webkit-transform:none; transform:none; }
  33%{ -webkit-transform:translateY(-1px) rotate(-2deg); transform:translateY(-1px) rotate(-2deg); }
  66%{ -webkit-transform:translateY(1px) rotate(2deg); transform:translateY(1px) rotate(2deg); }
}


@keyframes float {
  0%,100%{ -webkit-transform:none; transform:none; }
  33%{ -webkit-transform:translateY(-1px) rotate(-2deg); transform:translateY(-1px) rotate(-2deg); }
  66%{ -webkit-transform:translateY(1px) rotate(2deg); transform:translateY(1px) rotate(2deg); }
}

.float { display:inline-block; -webkit-animation:float .2s ease-in-out infinite; animation:float .2s ease-in-out infinite; }
.float-hover:hover { display:inline-block; -webkit-animation:float .2s ease-in-out infinite; animation:float .2s ease-in-out infinite; }


@-webkit-keyframes jump {
  0%,100%{ -webkit-transform:none; transform:none; }
  33%{ -webkit-transform:translateY(-1px) rotate(-0.1deg); transform:translateY(-1px) rotate(-0.1deg); }
  66%{ -webkit-transform:translateY(1px) rotate(0.1deg); transform:translateY(1px) rotate(0.1deg); }
}


@keyframes jump {
  0%,100%{ -webkit-transform:none; transform:none; }
  33%{ -webkit-transform:translateY(-1px) rotate(-0.1deg); transform:translateY(-1px) rotate(-0.1deg); }
  66%{ -webkit-transform:translateY(1px) rotate(0.1deg); transform:translateY(1px) rotate(0.1deg); }
}

.jump { display:inline-block; -webkit-animation:jump .2s ease-in-out infinite; animation:jump .2s ease-in-out infinite; }


@-webkit-keyframes bounce {
  0%,100%{ -webkit-transform:translate(0); transform:translate(0); }
  25%{ -webkit-transform:rotateX(20deg) translateY(2px) rotate(-3deg); transform:rotateX(20deg) translateY(2px) rotate(-3deg); }
  50%{ -webkit-transform:translateY(-20px) rotate(3deg) scale(1.1); transform:translateY(-20px) rotate(3deg) scale(1.1);  }
}


@keyframes bounce {
  0%,100%{ -webkit-transform:translate(0); transform:translate(0); }
  25%{ -webkit-transform:rotateX(20deg) translateY(2px) rotate(-3deg); transform:rotateX(20deg) translateY(2px) rotate(-3deg); }
  50%{ -webkit-transform:translateY(-20px) rotate(3deg) scale(1.1); transform:translateY(-20px) rotate(3deg) scale(1.1);  }
}

:hover.bounce-group .bounce-hover { -webkit-animation:bounce .6s infinite; animation:bounce .6s infinite; }
:hover.bounce-hover { -webkit-animation:bounce .6s infinite; animation:bounce .6s infinite; }
.bounce { -webkit-animation:bounce .6s infinite; animation:bounce .6s infinite; }

.bounce-group .bounce-delayed:nth-child(2){ -webkit-animation-delay:.05s; animation-delay:.05s; }
.bounce-group .bounce-delayed:nth-child(3){ -webkit-animation-delay:.1s; animation-delay:.1s; }
.bounce-group .bounce-delayed:nth-child(4){ -webkit-animation-delay:.15s; animation-delay:.15s; }
.bounce-group .bounce-delayed:nth-child(5){ -webkit-animation-delay:.2s; animation-delay:.2s; }
.bounce-group .bounce-delayed:nth-child(6){ -webkit-animation-delay:.25s; animation-delay:.25s; }
.bounce-group .bounce-delayed:nth-child(7){ -webkit-animation-delay:.3s; animation-delay:.3s; }
.bounce-group .bounce-delayed:nth-child(8){ -webkit-animation-delay:.35s; animation-delay:.35s; }
.bounce-group .bounce-delayed:nth-child(9){ -webkit-animation-delay:.4s; animation-delay:.4s; }
.bounce-group .bounce-delayed:nth-child(10){ -webkit-animation-delay:.45s; animation-delay:.45s; }
.bounce-group .bounce-delayed:nth-child(11){ -webkit-animation-delay:.5s; animation-delay:.5s; }
.bounce-group .bounce-delayed:nth-child(12){ -webkit-animation-delay:.55s; animation-delay:.55s; }
.bounce-group .bounce-delayed:nth-child(13){ -webkit-animation-delay:.6s; animation-delay:.6s; }
.bounce-group .bounce-delayed:nth-child(14){ -webkit-animation-delay:.65s; animation-delay:.65s; }

