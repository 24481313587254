.Home .cover {
  /* width: 100%; */
  margin-bottom: 1vw;
}

@media only screen and (max-width: 768px) {
  .Home .cover {
    /* width: 96%; */
  }
}
