/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* colorful spans */

.color:nth-child(4n) { 
  color:hsl(50, 75%, 55%); 
  text-shadow:1px 1px hsl(50, 75%, 45%), 2px 2px hsl(50, 45%, 45%), 3px 3px hsl(50, 45%, 45%), 4px 4px hsl(50, 75%, 45%); 
}
.color:nth-child(4n-1) { 
  color:hsl(135, 35%, 55%); 
  text-shadow:1px 1px hsl(135, 35%, 45%), 2px 2px hsl(135, 35%, 45%), 3px 3px hsl(135, 35%, 45%), 4px 4px hsl(135, 35%, 45%); 
}
.color:nth-child(4n-2) { 
  color:hsl(155, 35%, 60%); 
  text-shadow:1px 1px hsl(155, 25%, 50%), 2px 2px hsl(155, 25%, 50%), 3px 3px hsl(155, 25%, 50%), 4px 4px hsl(140, 25%, 50%); 
}
.color:nth-child(4n-3) { 
  color:hsl(30, 65%, 60%); 
  text-shadow:1px 1px hsl(30, 45%, 50%), 2px 2px hsl(30, 45%, 50%), 3px 3px hsl(30, 45%, 50%), 4px 4px hsl(30, 45%, 50%); 
}

.color-fixed { 
  color:hsl(155, 35%, 60%); 
  text-shadow:1px 1px hsl(11, 11%, 11%), 2px 2px hsl(155, 25%, 50%), 3px 3px hsl(155, 25%, 50%), 4px 4px hsl(140, 25%, 50%); 
}

/* animations */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:hover.spin-hover { animation: spin infinite 20s linear; }


@keyframes float {
  0%,100%{ transform:none; }
  33%{ transform:translateY(-1px) rotate(-2deg); }
  66%{ transform:translateY(1px) rotate(2deg); }
}

.float { display:inline-block; animation:float .2s ease-in-out infinite; }
.float-hover:hover { display:inline-block; animation:float .2s ease-in-out infinite; }


@keyframes jump {
  0%,100%{ transform:none; }
  33%{ transform:translateY(-1px) rotate(-0.1deg); }
  66%{ transform:translateY(1px) rotate(0.1deg); }
}

.jump { display:inline-block; animation:jump .2s ease-in-out infinite; }


@keyframes bounce {
  0%,100%{ transform:translate(0); }
  25%{ transform:rotateX(20deg) translateY(2px) rotate(-3deg); }
  50%{ transform:translateY(-20px) rotate(3deg) scale(1.1);  }
}

:hover.bounce-group .bounce-hover { animation:bounce .6s infinite; }
:hover.bounce-hover { animation:bounce .6s infinite; }
.bounce { animation:bounce .6s infinite; }

.bounce-group .bounce-delayed:nth-child(2){ animation-delay:.05s; }
.bounce-group .bounce-delayed:nth-child(3){ animation-delay:.1s; }
.bounce-group .bounce-delayed:nth-child(4){ animation-delay:.15s; }
.bounce-group .bounce-delayed:nth-child(5){ animation-delay:.2s; }
.bounce-group .bounce-delayed:nth-child(6){ animation-delay:.25s; }
.bounce-group .bounce-delayed:nth-child(7){ animation-delay:.3s; }
.bounce-group .bounce-delayed:nth-child(8){ animation-delay:.35s; }
.bounce-group .bounce-delayed:nth-child(9){ animation-delay:.4s; }
.bounce-group .bounce-delayed:nth-child(10){ animation-delay:.45s; }
.bounce-group .bounce-delayed:nth-child(11){ animation-delay:.5s; }
.bounce-group .bounce-delayed:nth-child(12){ animation-delay:.55s; }
.bounce-group .bounce-delayed:nth-child(13){ animation-delay:.6s; }
.bounce-group .bounce-delayed:nth-child(14){ animation-delay:.65s; }
