.Header {
  background-color: rgb(0, 87, 70);
  width: 100%;
  margin-top: 1%;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "BebasRegular", "Helvetica Neue", Helvetica, Arial,
    sans-serif; */
  font-family: "BebasRegular", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.navbar-nav {
  /* margin-right: 20vw; */
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 2vw;
  border: 2px solid;
  border-radius: 5px;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  /* color: rgb(198, 192, 192); */
  color: rgba(255, 255, 255, 0.9);
}

@media only screen and (max-width: 950px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 20px;
    padding: 2px;
    margin: 2px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-light .navbar-nav .nav-link {
    border: 0;
    padding: 1px 5px;
    margin: 1px 0;
  }
}

.navbar-toggler {
  border-width: 2px;
}

.navbar-toggler-icon {
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5);") */
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1);")
}

/* iphone 4 format */
@media only screen and (max-width: 320px) {
  .navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.5);
    padding: 0;
  }
}
